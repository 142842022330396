<template>
  <div id="phone">
    <div class="content">
      <div class="tit">
        <img src="../assets/logo.png" class="logo" />
        易初明通APP
      </div>
      <div class="main">
        <div class="phone">
          <img src="../assets/phone.png" class="bk" />
          <img src="../assets/cz.gif" class="gif" />
        </div>
      </div>
      <div class="footer">
        <a
          href="https://apps.apple.com/us/app/%E6%98%93%E5%88%9D%E6%98%8E%E9%80%9A-%E5%AE%A2%E6%88%B7%E8%BD%AF%E4%BB%B6/id1598843356?chInfo=ch_share__chsub_CopyLink&apshareid=71e44df0-9440-4d8e-91a4-3c5fee63dc80"><img
            src="../assets/ios.png" alt="" /></a>
        <!-- <a href="https://customerapp.eci-metro.cn/file/易初明通.apk"><img src="../assets/an.png" alt="" /></a> -->
        <div @click="down"><img src="../assets/an.png" alt="" /></div>
      </div>
      <div class="mask" v-show="show">
        <img src="../assets/rgba.png" class="img">
       <div style="text-align: center;display: flex;justify-content: space-around;">
        <div class="btn" @click="show=false">
          知道了
        </div>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  },
  created() {

  },
  methods: {
    down() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.includes('micromessenger')) {
        this.show = true
      } else {
        window.location.href = 'https://customerapp.eci-metro.cn/file/down/H5500C46A_0727092629.apk'

      }
    },
  }
};
</script>

<style lang="less" scoped>
#phone {
  .mask {
    .btn{
      margin-top: 120px;
      width: 160px;
      line-height: 40px;
      border-radius: 20px;
      text-align: center;
      font-weight: bold;
      background: #fff;
      border: 1px solid #000;
    }
    .img {
      width: 260px;
      margin-right: 20px;
      margin-top: 10px;
    }

    background: rgba(0, 0, 0, 0.8);
    text-align: right;
    z-index: 99;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .content {
    .footer {
      margin-top: 45px;

      a {
        display: block;
        margin: 9px;
      }
    }

    .main {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .phone {
        .gif {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 142px;
          height: 308px;
          border-radius: 15px;
          z-index: 9;
        }

        .bk {
          position: relative;
          width: 159px;
          height: 321px;
          z-index: 99;
        }

        position: relative;
      }
    }

    text-align: center;

    .tit {
      .logo {
        width: 22px;
        vertical-align: middle;
      }

      color: #fff;
    }

    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
  }

  background: url(../assets/bg1.png);
  background-size: cover;
  height: 100%;
  width: 100%;
}
</style>