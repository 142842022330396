<template>
  <div id="pc">
    <div class="main">
      <div class="content">
        <div class="left">
          <img src="../assets/phone.png" style="width: 236px; height: 474px" />
          <video
            src="http://customerapptest.eci-metro.cn:7070/czsp.MP4"
            autoplay="autoplay"
            loop="loop"
            muted="muted"
          ></video>
        </div>
        <div class="right">
          <div class="top">
            <img src="../assets/logo2.png" class="login" />
            易初明通APP
          </div>
          <div class="chagnge">选择版本后，识别二维码下载</div>
          <div class="btm">
            <div class="an">
              <img
                src="../assets/i1.png"
                @mouseover="anmove"
                @mouseout="anout"
                class="cl"
              />
              <img src="../assets/ioss.png" class="pic" v-show="an" />
            </div>
            <div class="ios">
              <img
                src="../assets/i2.png"
                @mouseover="iosmove"
                @mouseout="iosout"
                class="cl"
              />
              <img src="../assets/ann.png" class="pic" v-show="ios" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" > 蜀ICP备13014520号-16</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      an: false,
      ios: false,
    };
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    go() {
      window.location.href = "https://beian.miit.gov.cn/#/Integrated/index";
    },
    anmove() {
      this.an = true;
    },
    iosmove() {
      this.ios = true;
    },
    anout() {
      this.an = false;
    },
    iosout() {
      this.ios = false;
    },
  },
  mounted() {
    // if (this._isMobile()) {
    //   this.$router.push("/home");
    // }
  },
};
</script>

<style lang="less" scoped>
#pc {
  .footer {
    font-size: 14px;
    color: #fff;
    position: absolute;
    bottom: 20px;
    text-align: center;
    left: 45%;
    cursor: pointer;
    a:link {
      color: #fff;
      outline: none;
    }
    a:visited {
      color: #fff;
      outline: none;
    }
    a {
      outline: none;
      border: none;
    }
  }
  .login {
    height: 42px;
    vertical-align: middle;
  }
  .main {
    position: absolute;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .content {
      .right {
        .chagnge {
          margin-bottom: 20px;
          font-size: 14px;
          color: #ccc;
        }
        .btm {
          .an {
            position: relative;
            margin-right: 20px;
          }
          .ios {
            position: relative;
          }
          display: flex;
          justify-content: center;
          align-items: center;
          .pic {
            // width: 140px;
            // height: 144px;
            position: absolute;
            left: 0px;
            top: 6px;
            z-index: 9;
          }
          .cl {
            width: 138px;
            height: 37px;
            display: block;
            cursor: pointer;
            z-index: 99;
            position: relative;
          }
        }
        .top {
          font-size: 44px;
          margin-bottom: 20px;
          color: #fff;
        }
        display: flex;
        flex-direction: column;
      }
      .left {
        img {
          z-index: 99;
          position: relative;
        }
        video {
          width: 230px;
          height: 460px;
          border-radius: 38px;
          position: absolute;
          left: 2px;
          top: 7px;
        }
        margin-right: 60px;
        position: relative;
      }
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  height: 100%;
  width: 100%;
  background-image: url(../assets/bc.png);
  background-size: cover;
}
</style>
